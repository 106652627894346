.comment {
    padding-top: .5rem;
    width: 100%;
    margin-bottom: .2rem;
    
    .comment-meta {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .author {
            display: flex;
            justify-content: space-between;
        }

        img {
            width: 50px !important;
            height: auto;
            margin-right: .5rem;
        }
    }
}