.MuiDrawer-paperAnchorRight {
    background-color: var(--brown);
    border-left: 2px solid var(--darkbrown);
    min-width: 350px;
  
    h2 { margin-left: 1rem; }
  
    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
      li {
        display: block;
        a {
          display: block;
          padding: 1rem;
          color: var(--darkbrown);
          text-decoration: none;
          border-bottom: 1px dotted var(--brown);
  
          &:hover {
            background: var(--orange);
            border-bottom: 1px solid var(--darkbrown);
          }
        }
      }
    }
  }

  .MuiButton-containedPrimary {
    margin: .5rem !important;

    img {
      max-width: 50px;
      margin-right: .5rem;
      border-radius: 5px;
    }
  }

  .MuiStepper-horizontal {
    margin-bottom: 1rem;
  }