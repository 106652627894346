  /** BREAKPOINTS
  Usage:
  @include breakpoint(tablet) {}
  @include breakpoint(min-width, 1192px) {}
  @include between-breakpoints(0, 1024px) {}
  
  **/
@mixin breakpoint($point, $value: 0) {
    @if $point == mobile {
          @media (min-width: 320px) { @content; }
      }
  
      @else if $point == mobile-horizontal {
          @media (min-width: 480px) { @content; }
      }
  
      @else if $point == tablet {
          @media (min-width: 768px) { @content; }
      }
  
      @else if $point == tablet-horizontal {
          @media (min-width: 1024px) { @content; }
      }
      
      @else if $point == desktop {
          @media (min-width: 1280px) { @content; }
      }
  
      @else if $point == desktop-wide {
          @media (min-width: 1500px) { @content; }
      }
  
      @else {
          @media ($point: $value) { @content; }
      }
  }
  
  @mixin between-breakpoints($min, $max) {
      @media (min-width: $min) and (max-width: $max) {
          @content;
      }
  }