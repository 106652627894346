:root {
  --brown: #914700;
  --darkbrown: #4B2303;
  --yellow: #FCCE09;
  --orange: #FFB001;
}

@import 'sass/helpers';
@import 'sass/mui_modifications';

body {
  background-color: var(--brown);
  color: #fff;
  padding-bottom: 100px;
}

header {
  background: var(--darkbrown);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;

  #logo {
    width: 50%;
    max-width: 150px;
    img {
      width: 100%;
      margin-right: .5rem;
    }
  }

  .navProfilepic {
    border-radius: 5px;
  }
  .MuiSvgIcon-root {
    background-color: var(--yellow);
    color: var(--darkbrown);
    padding: .5rem;
    border-radius: 5px;
  }

  ul {
    display: flex;
    justify-content: space-between;
    list-style-type: none;
    margin: 0;
    padding: 0;
    gap: .5rem;
  }
}

#usermenu {
  a {
    text-decoration: none;
    color: #000;

    svg {
      margin-right: .5rem;
    }
  }
}

main {
  width: 100%;
  max-width: 1200px;
  margin: 1rem auto;
  position: relative;

  .flex-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 1rem;
  }
  .text-container {
    max-width: 55rem;
    padding-left: 1rem;
    padding-right: 1rem;

    h2, h3 {
      padding-left: 1rem;
    }

    ul {
      line-height: 1.4rem;

      li {
        margin-bottom: .5rem;
      }
    }

    a {
      color: var(--yellow);
      text-decoration: solid underline var(--darkbrown) 3px;
      text-underline-position: under;
      transition: ease-in-out .4s;
      display: inline-block;
      padding: 0 5px;

      &:hover {
        background: var(--yellow);
        color: var(--darkbrown);
      }
    }
  }

  h1 {
    display: block;
    width: 100%;
    margin-bottom: 1rem;
    position: sticky;
    top: 0;
    padding: 1rem;
    background-color: var(--brown);
    z-index: 99;
    font-size: 2rem;
  }
}



#add {
  width: 90%;
  margin: 0 auto;
  form {
    width: 100%;
    overflow: hidden;
  }
  textarea {
    width: 100%;
    @include breakpoint(tablet) {
      width: 50%;
      clear: both;
    }
    
    min-height: 70px;
    padding: 1rem;
    margin: 1rem 0;
  }

  .uploadedImage {
    max-width: 48%;
    float: left;
    margin-right: 1rem;
  }

  .addProfile {
    width: 100%;
    padding: .5rem;
    background-color: var(--darkbrown);
    margin: 1rem 0;
    min-height: 77px;
    box-sizing: border-box;

    p {
      line-height: 1.2rem;
      margin: 0;
    }

    img {
      width: 75px;
      float: left;
      margin-right: 1rem;
    }
  }

  a {
    color: var(--yellow);
    text-decoration: solid underline var(--darkbrown) 3px;
    text-underline-position: under;
    transition: ease-in-out .4s;
    display: inline-block;
    padding: 0 5px;

    &:hover {
      background: var(--yellow);
      color: var(--darkbrown);
    }
  }
}

footer {
  width: 100%;
  display: block;
  background-color: var(--darkbrown);
  position: fixed;
  bottom: 0;
  left: 0;
  gap: 2px;
  padding-top: 2px;
  display: flex;
  align-items: center;
  justify-content: space-around;

  a {
    padding: 1rem;
    flex-grow: 1; 
    box-sizing: border-box;
    background-color: var(--yellow);
    color: var(--darkbrown);
    display: flex;
    justify-content: space-around;
    align-items: center;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      background-color: var(--orange);
    }
  }
}

@import 'sass/post';
@import 'sass/singlePhoto';
@import 'sass/comments';
@import 'sass/solopage';