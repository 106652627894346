#singlePhoto {
    width: 100%;
    max-width: 700px;
    margin: 0 auto;
    position: relative;
    padding: .5rem;
    box-sizing: border-box;

    @include breakpoint(tablet) {
        padding: 0;
    }

    .meta {
        width: 100%;
        box-sizing: border-box;
        /*display: flex;
        justify-content: space-between;
        align-items: center;*/
        display: grid;
        grid-template-columns: 50px auto auto;
        grid-template-rows: 25px 25px;
        grid-template-areas:    "pic name comment"
                                "pic date comment";

        img.profilepic {
            width: 50px;
            height: 50px;
            grid-area: pic;
        }
        .name {
            grid-area: name;
            padding-left: .5rem;
        }
        .date {
            grid-area: date;
            padding-left: .5rem;
            font-size: .8rem;
        }
        .commentCount {
            text-align: right;
            grid-area: comment;
        }
    }

    img {
        width: 100%;
        max-width: 700px;
    }

    form {
        width: 100%;
        padding: .5rem;
        margin: 1rem  auto;
        box-sizing: border-box;
        border: 1px solid var(--darkbrown);
        background: #fff;

        h3 {
            color: var(--darkbrown);
        }

        textarea {
            width: 100%;
            font-size: 1rem;
            color: var(--darkbrown);
        }

        button {
            float: right;
        }
    }
}