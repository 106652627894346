article {
    position: relative;
    width: 100%;
    padding: calc(50px + .5rem) .5rem .5rem .5rem;
    background: var(--darkbrown);
    box-sizing: border-box;
    a {
        color: #fff;
        text-decoration: none;
    }
    
    &.personPost {
      padding-top: 1rem;
    }
    
    @include breakpoint(tablet) {
      width: 32%;
  
    }

    .meta {
        position: absolute;
        top: .5rem;
        left: .5rem;
        width: calc(100% - 1rem);
        /*display: flex;
        justify-content: space-between;
        align-items: center;*/
        display: grid;
        grid-template-columns: 50px auto auto;
        grid-template-rows: 25px 25px;
        grid-template-areas:    "pic name comment"
                                "pic date comment";

        img.profilepic {
            width: 50px;
            height: 50px;
            grid-area: pic;
        }
        .name {
            grid-area: name;
            padding-left: .5rem;
        }
        .date {
            grid-area: date;
            padding-left: .5rem;
            font-size: .8rem;
        }
        .commentCount {
            text-align: right;
            grid-area: comment;
        }
    }
  
    img {
        width: 100%;
    }
  
  }